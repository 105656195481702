@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=latin-ext");
@font-face {
  font-family: "custom-icons";
  src: url("./fonts/custom-icons.eot?c5d981a33216e414dd140837d8f9c07b?#iefix") format("embedded-opentype"), url("./fonts/custom-icons.woff2?c5d981a33216e414dd140837d8f9c07b") format("woff2"), url("./fonts/custom-icons.woff?c5d981a33216e414dd140837d8f9c07b") format("woff"), url("./fonts/custom-icons.ttf?c5d981a33216e414dd140837d8f9c07b") format("truetype"), url("./fonts/custom-icons.svg?c5d981a33216e414dd140837d8f9c07b#custom-icons") format("svg"); }

i {
  line-height: 1; }

i[class^="icon-"]::before, i[class*=" icon-"]::before {
  font-family: custom-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-3d::before {
  content: "\f101"; }

.icon-baseline-battery_20::before {
  content: "\f102"; }

.icon-baseline-battery_30::before {
  content: "\f103"; }

.icon-baseline-battery_50::before {
  content: "\f104"; }

.icon-baseline-battery_60::before {
  content: "\f105"; }

.icon-baseline-battery_80::before {
  content: "\f106"; }

.icon-baseline-battery_90::before {
  content: "\f107"; }

.icon-baseline-battery_alert::before {
  content: "\f108"; }

.icon-baseline-battery_charging_20::before {
  content: "\f109"; }

.icon-baseline-battery_charging_30::before {
  content: "\f10a"; }

.icon-baseline-battery_charging_50::before {
  content: "\f10b"; }

.icon-baseline-battery_charging_60::before {
  content: "\f10c"; }

.icon-baseline-battery_charging_80::before {
  content: "\f10d"; }

.icon-baseline-battery_charging_90::before {
  content: "\f10e"; }

.icon-baseline-battery_charging_full::before {
  content: "\f10f"; }

.icon-baseline-battery_full::before {
  content: "\f110"; }

.icon-baseline-battery_unknown::before {
  content: "\f111"; }

.icon-battery::before {
  content: "\f112"; }

.icon-caret-down::before {
  content: "\f113"; }

.icon-caret-up::before {
  content: "\f114"; }

.icon-copy::before {
  content: "\f115"; }

.icon-email::before {
  content: "\f116"; }

.icon-info::before {
  content: "\f117"; }

.icon-like::before {
  content: "\f118"; }

.icon-settings-work-tool::before {
  content: "\f119"; }

.icon-sign-in::before {
  content: "\f11a"; }

.icon-statistics::before {
  content: "\f11b"; }

.icon-warning-sign::before {
  content: "\f11c"; }

.icon-warning::before {
  content: "\f11d"; }

.text--pink {
  color: #ff005d; }

.text--success {
  color: #e8ff00; }

.text--center {
  text-align: center; }

.text--cursive {
  font-style: italic; }

.text--green {
  color: #b3f900;
  text-shadow: 0 0 15px rgba(61, 99, 0, 0.5), 0 0 3px #3d6300; }

.btn {
  align-content: center;
  align-items: center;
  background: #97ce23;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 15px rgba(61, 99, 0, 0.5), 0 0 3px #3d6300;
  color: #cacaca;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: .85rem;
  font-weight: 700;
  line-height: 1.1;
  padding: .45rem;
  text-shadow: #b3f900 0 0 5px;
  text-transform: uppercase;
  transition: all .25s ease-in-out; }
  .btn--danger {
    background: #bf0000; }
  .btn:hover {
    outline: 2px #b3f900 auto;
    text-shadow: #b3f900 0 0 5px; }

.form input,
.form select,
.form textarea {
  background: rgba(128, 128, 128, 0.07);
  border: 0;
  box-sizing: border-box;
  color: #f5f5f5;
  font-family: inherit;
  font-size: .95rem;
  font-weight: 600;
  margin: 5px 0;
  padding: 5px 10px;
  text-align: left;
  width: 100%; }
  .form input textarea,
  .form select textarea,
  .form textarea textarea {
    max-width: 100%; }
  .form input.blank,
  .form select.blank,
  .form textarea.blank {
    color: rgba(202, 202, 202, 0.2); }
  .form input option,
  .form select option,
  .form textarea option {
    color: #000; }
    .form input option:disabled,
    .form select option:disabled,
    .form textarea option:disabled {
      color: rgba(0, 0, 0, 0.45); }
  .form input:focus,
  .form select:focus,
  .form textarea:focus {
    outline-color: #cacaca; }

.form label {
  color: rgba(202, 202, 202, 0.4);
  display: flex;
  flex-direction: column;
  font-size: .95rem;
  margin-top: 1rem; }
  .form label.checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .form label.checkbox [type="checkbox"] {
      box-shadow: none;
      cursor: pointer;
      display: inline-flex;
      flex-grow: 0;
      margin: 0;
      min-width: 4rem;
      padding-right: 1rem;
      transform: scale(2);
      width: auto; }
    .form label.checkbox em {
      flex-grow: 1;
      font-size: .95rem;
      font-style: normal;
      text-align: left;
      text-transform: none; }

.form__group {
  margin-bottom: 1rem; }

.form__required {
  color: #bf0000;
  margin-left: .15rem; }

table {
  border-collapse: collapse;
  width: 100%; }
  table th {
    background: transparent;
    color: #97ce23;
    padding: .5rem;
    text-align: left;
    text-shadow: 0 0 15px rgba(61, 99, 0, 0.5), 0 0 3px #3d6300;
    text-transform: uppercase; }
  table td {
    padding: .5rem; }
  table tr:nth-of-type(even) {
    background: rgba(61, 99, 0, 0.02); }

* {
  box-sizing: border-box; }

::selection {
  background: #aedf45;
  color: #cacaca; }

html,
body {
  background: #000;
  background-size: cover;
  color: #cacaca;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  padding: 0; }
  html .app-wrapper,
  body .app-wrapper {
    display: flex;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%; }

h1,
h2,
h3,
h4,
h5 {
  color: #f5f5f5;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 0;
  text-shadow: 0 0 15px rgba(61, 99, 0, 0.5), 0 0 3px #3d6300;
  text-transform: uppercase; }

h2 {
  border-bottom: 2px solid rgba(151, 206, 35, 0.5);
  color: #97ce23; }

a {
  color: #e5aa00; }
  a.link {
    color: #e5aa00;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 0 0 3px rgba(229, 170, 0, 0.25); }
  a.image-link {
    color: inherit;
    text-decoration: none; }

.app-wrapper {
  display: flex;
  justify-content: center;
  position: static;
  transition: background .5s ease-in-out;
  width: 100%; }
  .app-wrapper .app-theme {
    display: flex;
    justify-content: center;
    position: static;
    transition: background .5s ease-in-out;
    width: 100%; }
  .app-wrapper.clear {
    background: #000; }
  .app-wrapper:not(.login) .app-theme {
    background: rgba(0, 0, 0, 0.8); }

.social-bar {
  bottom: 3rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.8);
  display: flex;
  position: absolute;
  right: 3rem; }

p {
  text-shadow: 0 0 15px rgba(61, 99, 0, 0.5), 0 0 3px #3d6300; }

.sorts a {
  color: inherit;
  font-size: .85rem;
  text-decoration: none; }
